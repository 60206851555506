import React from 'react'
import { TramitDetailDataStyles } from '../index.css'
import { Box, Grid, styled, Typography } from '@mui/material';

const CustomTextTitle = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontSize: "1rem",
    marginBottom: "0px",
    maxWidth: '100%',
    whiteSpace: 'wrap',
    [props.theme.breakpoints.up('sm')]: {
        fontSize: "1.2rem",
        marginBottom: ".35rem"
    },
    [props.theme.breakpoints.up('lg')]: {
        maxWidth: '15rem',
    },
}));

const TramitDetail = ({
    tramitID,
    tramitIDAnf,
    tramitType,
    participantsNumber,
    tramitName,
    step
}) => {

    const styles = TramitDetailDataStyles();

    return (
        <Grid
            item
            xs={12}
        >
            <Box className={styles.numberTitle}>
                <Typography
                    className={styles.stepNumbers}
                    variant="h5"
                    gutterBottom
                >
                    {step}
                </Typography>

                <Typography
                    variant="h6"
                    gutterBottom
                    className={styles.detailTextTitle}
                >
                    Datos del trámite
                    <br />
                    <span className={styles.detailTextSecondaryTitle}>Datos del trámite a realizar</span>
                </Typography>
            </Box>

            <Box className={styles.mainInfo_box}>

                {tramitIDAnf && (
                    <Box className={styles.dataTramit}>
                        <CustomTextTitle
                            variant="h6"
                            gutterBottom
                            className={styles.ownerData_Info_Box_mainText}
                        >
                            ID del trámite ANF
                        </CustomTextTitle>

                        <Box className={styles.dataTramit_Info}>
                            <CustomTextTitle
                                variant="body1"
                                gutterBottom
                                className={styles.ownerData_Info_Box_secondaryText}
                            >
                                {tramitIDAnf}
                            </CustomTextTitle>
                        </Box>
                    </Box>
                )}

                {tramitID && (
                    <Box className={styles.dataTramit}>
                        <CustomTextTitle
                            variant="h6"
                            gutterBottom
                            className={styles.ownerData_Info_Box_mainText}
                        >
                            ID del trámite Express
                        </CustomTextTitle>

                        <Box className={styles.dataTramit_Info}>
                            <CustomTextTitle
                                variant="body1"
                                gutterBottom
                                className={styles.ownerData_Info_Box_secondaryText}
                            >
                                {tramitID}
                            </CustomTextTitle>
                        </Box>
                    </Box>
                )}

                <Box className={styles.dataTramit}>
                    <CustomTextTitle
                        variant="h6"
                        gutterBottom
                        className={styles.ownerData_Info_Box_mainText}
                    >
                        Tipo de firma solicitada
                    </CustomTextTitle>

                    <Box className={styles.dataTramit_Info}>
                        <CustomTextTitle
                            variant="body1"
                            gutterBottom
                            className={styles.ownerData_Info_Box_secondaryText}
                        >
                            {tramitType}
                        </CustomTextTitle>
                    </Box>
                </Box>

                <Box className={styles.dataTramit}>
                    <CustomTextTitle
                        variant="h6"
                        gutterBottom
                        className={styles.ownerData_Info_Box_mainText}
                    >
                        Participantes
                    </CustomTextTitle>

                    <Box className={styles.dataTramit_Info}>
                        <CustomTextTitle
                            variant="body1"
                            gutterBottom
                            className={styles.ownerData_Info_Box_secondaryText}
                        >
                            {participantsNumber}
                        </CustomTextTitle>
                    </Box>
                </Box>

                <Box className={styles.dataTramit}>
                    <CustomTextTitle
                        variant="h6"
                        gutterBottom
                        className={styles.ownerData_Info_Box_mainText}
                    >
                        Nombre del trámite
                    </CustomTextTitle>

                    <Box className={styles.dataTramit_Info}>
                        <CustomTextTitle
                            variant="body1"
                            gutterBottom
                            className={styles.ownerData_Info_Box_secondaryText}
                        >
                            {tramitName}
                        </CustomTextTitle>
                    </Box>
                </Box>

            </Box>

        </Grid>
    )
}

export default TramitDetail